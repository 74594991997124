import React from 'react';
const axios = require('axios').default;
const formStyle = {
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-around',
	alignContent: 'center',
};

const inputStyle = {
	width: '350px',
	height: ' 40px',
	padding: '10px',
	borderRadius: '5px',
	fontWeight: 'lighter',
	outline: 'none',
	border: 'none',
	WebkitBoxShadow: '-1px 1px 3px 0px rgba(0,0,0,0.75)',
	MozBoxShadow: '-1px 1px 3px 0px rgba(0,0,0,0.75)',
	boxShadow: '-1px 1px 3px 0px rgba(0,0,0,0.75)',
};

const MailChimpBanner = () => {
	const levels = ['beginner', 'intermediate', 'advanced'];
	const handleSubmit = async (e) => {
		e.preventDefault();

		const emailField = document.querySelector('#subscriber-email');
		const email = emailField.value;
		const levelField = document.querySelector('#subscriber-level');
		const level = levelField.value;

		try {
			await axios.post(
				`https://langi.app/api/preauth/addToHowToVietnamese`,
				{
					params: {
						email,
						type: 'homepage_form',
						level: level,
					},
				},
				{
					headers: {
						authorization: 'howtovietnamese!',
					},
				}
			);
			alert('Thank you!');
			emailField.value = '';
			levelField.value = '';
		} catch (error) {
			console.log(error);
			alert('There was an error, please try again');
		}
	};
	return (
		<div id="mailchimp-form">
			<div className="container pt-4 pt-md-6 pb-10 pl-2 pr-2 mt-0">
				<div className="row mailchimp-form-row">
					{/* <div className="col-12 col-md-4 mailchimp-form-title"> */}
					<h3 className="mailchimp-form-title mb-1">Be the first to know</h3>
					<p className="mailchimp-form-description mb-3">
						Join our Newsletter to receive monthly learning tips and updates!
					</p>

					<form onSubmit={handleSubmit}>
						<input name="seller_id" type="hidden" value="6890553118339"></input>
						<div className="form-row mt-1">
							<div className="col-md-4 mb-1">
								<input
									type="email"
									name="email"
									className="form-control"
									id="subscriber-email"
									placeholder="Your Email"
								/>
							</div>
							<div className="col-md-5 mb-1">
								<select className="form-control" id="subscriber-level">
									<option selected>What's your level?</option>
									<option value="Beginner">Beginner</option>
									<option value="Intermediate">Intermediate</option>
									<option value="Advanced">Advanced</option>
								</select>
							</div>
							<div className="col-md-3 mt-1 mt-md-0">
								<button
									type="submit"
									className="btn-sm btn-secondary align-center"
								>
									Sign me up!
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default MailChimpBanner;
