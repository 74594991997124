import React from "react";
import SmallCardBlog from "../components/SmallCardBlog";
import Layout from "../layouts/index";
import SEO from "../components/SEO";
import MailChimpBanner from "../components/Mailchimp-banner";
import PromoBanner from "../components/PromoBanner";
import LHTBanner from "../components/LHTBanner";
const Posts = (props) => {
  const allPosts = props.data.allMdx.edges;
  // const { currentPage, numPages } = props.pageContext;
  // const isFirst = currentPage === 1
  // const isLast = currentPage === numPages
  // const prevPage = currentPage - 1 === 1 ? "blog/" : `blog/${(currentPage - 1).toString()}`
  // const nextPage = `blog/${(currentPage + 1).toString()}`
  return (
    <Layout bodyclassName="page-services">
      <SEO
        title="Vietnamese Language Blog | Learn Southern Vietnamese with HowToVietnamese"
        description="Useful language blog that helps you learn Vietnamese. Access Free Conversational Vietnamese Online Lessons with Audio for Beginner & Intermediate at HowToVietnamese"
      />
      <PromoBanner />

      <div className="contentContainer jumbotron jumbotron-fluid margin-from-header strip-white">
        {/* <OutboundLink
          href="https://store.howtovietnamese.com/?coupon=50BLACKFRIDAY"
          target="_blank"
        >
          <img
            src={promoBanner}
            className="img-fluid mx-auto d-block"
            alt="Black Friday Banner"
          ></img>
        </OutboundLink> */}
        <div className="container">
          <div className="col-12 mr-1 pt-0 pt-md-3 ml-md-4">
            <h2>Featured Articles</h2>
            <p className="text-muted">
              Get to know Vietnam through the language, culture and travel tips.
            </p>
          </div>
        </div>
      </div>

      <div className="container d-flex justify-content-center pl-md-0 pr-md-0">
        <div className="col-12 col-md-11">
          <div className="row">
            {/* posts */}
            {allPosts.map((edge, i) => (
              <div className="col-12 col-md-4 mb-md-4">
                <SmallCardBlog key={i} edge={edge} />
              </div>
            ))}

            {/* pagination */}
            {/*
						<div className="row pl-2">
										{!isFirst && (
						<Link to={prevPage} rel="prev">
							← Previous Page
						</Link>
					)}
					{!isLast && (
						<Link to={nextPage} rel="next">
							Next Page →
						</Link>
					)}
            </div>
            */}

            {/*  Sidebar
          <div className="col-12 col-md-4">
            <div className="container">
              <Sidebar />
            </div>
          </div>
          */}
          </div>
          <div className="col-12 no-bg">
            <MailChimpBanner />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogListQuery {
    allMdx(
      filter: {
        frontmatter: {
          category: { nin: ["English", "", "Courses", "Lessons"] }
        }
        fileAbsolutePath: { regex: "/blog/" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            path
            title
            category
            excerpt
            date(formatString: "DD MMMM YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Posts;
